<template>
    <div class="ele-body">
        <el-card shadow="never">
            <div class="my-headline">活动规则</div>
            <div class="box-inside">
                <el-form :model="editForm"  label-width="140px">
                    <el-form-item label="新用户零钱活动：">
                        <tinymce-editor v-model="editForm.user_loose" :init="editUserCharge" placeholder="请输入商品详情"/>
                    </el-form-item>
                    <el-form-item label="零钱优惠活动：">
                        <tinymce-editor v-model="editForm.lottery" :init="editChargeDiscounts" placeholder="请输入商品详情"/>
                    </el-form-item>
                    <el-button type="primary" @click="save" style="float:right" class="mb-25" v-if="permission.includes('sys:activity_rule:edit')">确认提交</el-button>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor'
import { mapGetters } from "vuex";

export default {
    name: "SysUser",
    components: {TinymceEditor},
    data() {
        return {

            editForm: {},   // 表单数据
            checkForm:{},
            urgentList:[],
            imageUrl:false,
            headImg:'',
            // 自定义文件上传(这里使用把选择的文件转成blob演示)
            file_picker_callback: (callback, value, meta) => {
                let input = document.createElement('input');
                input.setAttribute('type', 'file');
                // 设定文件可选类型
                if (meta.filetype === 'image') {
                    input.setAttribute('accept', 'image/*');
                } else if (meta.filetype === 'media') {
                    input.setAttribute('accept', 'video/*');
                }
                input.onchange = () => {
                    let file = input.files[0];
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        let blob = new Blob([e.target.result], {type: file.type});
                        callback(URL.createObjectURL(blob));
                    };
                    reader.readAsArrayBuffer(file);
                }
                input.click();
            }
        }
    },
    created(){
      const loading = this.$loading({lock: true});
      this.$http.get('/finance/activity_rule').then(res=>{
        loading.close()
        let data=res.data
        this.editForm=data.data
      })
    },

    computed: {
        ...mapGetters(["permission"]),
		editUserCharge() {
			return {
				menubar: false,
				// file_picker_callback: this.file_picker_callback,
				skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' : '/tinymce/skins/ui/oxide',
				content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' : '/tinymce/skins/content/default/content.css'
			};
		},
        editChargeDiscounts() {
			return {
				menubar: false,
				// file_picker_callback: this.file_picker_callback,
				skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' : '/tinymce/skins/ui/oxide',
				content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' : '/tinymce/skins/content/default/content.css'
			};
		}
    },
    mounted() {
    },
    methods:{
      save(){
        const loading = this.$loading({lock: true});
        this.$http.post('/finance/activity_rule',{user_loose:this.editForm.user_loose,lottery:this.editForm.lottery}).then(res=>{
          loading.close()
          let data=res.data
          if(data.code === 1){
            this.$message({type: 'success', message:data.msg});
          }else{
            this.$message.error(data.msg);
          }
        }).catch(e=>{
          loading.close();
          this.$message.error(e.message);
        })
      },

    }
}
</script>

<style scoped>
    .ele-block .el-upload-dragger {
        width: 100%;
    }
    .ele-body{
        padding:15px;
    }
    .el-upload-list el-upload-list--text{display:none !important}
    .user-info-tabs >>> .el-tabs__nav-wrap {
        padding-left: 20px;
    }
    .box-inside{
        padding-left:100px;
        padding-top:10px;
        width:60%
    }
    .innerBoxTo{
      width:40%;
      margin-left:100px;
      padding-top: 20px;
    }
</style>

